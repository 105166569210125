body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #FFF !important;
}

// Wacky webpack(?) bug putting iframe on top of everything in dev mode
body > iframe {
  display: none !important;
}
